:root {
	--fornav_margin_top: 71px;
	--mask: linear-gradient(#000 0 0) center/calc(100% - 10px) calc(100% - 10px) no-repeat,

		conic-gradient(from 135deg at top, #0000, #000 1deg 89deg, #0000 90deg) 0 0 /10px 5px space no-repeat,

		conic-gradient(from -45deg at bottom, #0000, #000 1deg 89deg, #0000 90deg) 0 100%/10px 5px space no-repeat,

		conic-gradient(from 45deg at left, #0000, #000 1deg 89deg, #0000 90deg) 0 0 /5px 10px no-repeat space,

		conic-gradient(from -135deg at right, #0000, #000 1deg 89deg, #0000 90deg) 100% 0/5px 10px no-repeat space;
}

html,
body {
	height: 100%;
	font-family: 'Jost', sans-serif;
	font-weight: 400;
	max-width: 100%;
	scroll-behavior: smooth !important;
}

body {
	margin: 0;
}

body * {
	font-family: "Mulish", sans-serif !important;
	font-optical-sizing: auto;
	font-style: normal;
}

.imageViewer > .container {
	max-width: 100% !important;
}

.material-symbols-outlined {
	font-family: 'Material Symbols Outlined', sans-serif !important;
}

.test-b {
	outline: 1px solid red;
}

.fb-default-transition {
	-webkit-transition: all 0.20s ease-in-out;
	-moz-transition: all 0.20s ease-in-out;
	-o-transition: all 0.20s ease-in-out;
	transition: all 0.20s ease-in-out;
}

.page-container {
	width: 95%;
	margin: 0 auto;
	max-width: 1300px;
}

.story_action_wrapper button {
	position: relative !important;
}

.story_action_wrapper .social_icon_cont {
	position: absolute !important;
	right: 100px;
	top: 0;
}

.page-details-container {
	flex-wrap: wrap;
	display: flex;
	width: 100%;
	gap: 20px;
}

.page-details-container-wrapper {
	flex-wrap: wrap;
	display: flex;
	width: 100%;
	gap: 20px;
	padding-top: 20px;
	align-items: center;
	justify-content: center;
}

.card_item {
	width: calc(25% - 20px);
}

.fadein_animation {
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease-out;
}

*:hover>.fadein_animation {
	visibility: visible;
	opacity: 1;
	transition: all .3s ease-out;
}

.slidein_animation {
	transform: translateY(5px);
	opacity: 0; //TODO: opacity 0 in prod
	// opacity: 1; //TODO: remove after development
	// visibility: visible; //TODO: remove this line
	transition: all 0s ease-out;
}

*:hover>.slidein_animation {
	transform: translateY(0px);
	opacity: 1;
	transition: all .3s ease-out;
}

// navigation destop start common
.desktop_nav_cont {
	transition: all .5s;
	max-height: 300px;
	position: fixed;
	width: 100%;
	left: 0;
	z-index: 2;
}

.desktop_nav_cont.scrolled {
	position: fixed;
	top: 0;
	width: 100%;
	left: 0;
	max-height: 70px;
	transition: all .5s;
	z-index: 9;
}

.desktop_nav_cont.scrolled .static_logo_container {
	background-color: white;
	transition: all .5s;
}

.desktop_nav_cont .static_logo_container * {
	color: white;
}

.desktop_nav_cont.scrolled .static_logo_container .col_left_dropdown {
	top: 19px;
}

.desktop_nav_cont .static_logo_container .forex_container select option {
	color: black;
}

.desktop_nav_cont.home_page_not_active .static_logo_container {
	background: white;
	transition: all .5s;
}

.desktop_nav_cont .col_center span {
	font-size: 1.3rem;
	transition: all .5s;
}

.home_page_not_active.desktop_nav_cont .cat_menu {
	background: #28282d;
}

.desktop_nav_cont.scrolled .col_center span {
	font-size: 1.2rem;
	transition: all .5s;
}

.desktop_nav_cont.scrolled .menu_static {
	font-size: 12px;
}

.menu_ind {
	transition: all ease-in-out .5s;
}

.desktop_nav_cont.scrolled .menu_ind {
	padding: 3px 10px;
	font-size: 13px;
	transition: all ease-in-out .5s;
}

.desktop_nav_cont.home_page_not_active {
	position: relative;
	z-index: 5;
	transition: all .5s;
}

.desktop_nav_cont.home_page_not_active.scrolled {
	position: fixed;
	z-index: 5;
	opacity: 1;
	transition: all .5s;
}

.desktop_nav_cont.home_page_not_active.popupOpen {
	// z-index: 0;
	// opacity: 0;
	position: static;
	transition: all .5s;
}

.desktop_nav_cont.home_page_not_active.scrolled.popupOpen {
	opacity: 0;
	transition: all .5s;
}

.home_page_not_active.a-main-margin {
	margin-top: 0;
}

.desktop_nav_cont.scrolled .menu_static div {
	display: none;
}

.desktop_nav_cont.scrolled .menu_static span {
	display: block;
	font-size: 19px;
	margin-top: 0;
}

.desktop_nav_cont.scrolled .menu_static span.image_icon_cont .dark_image {
	display: block;
}

.desktop_nav_cont.scrolled .menu_static span.image_icon_cont .light_image {
	display: none;
}

.desktop_nav_cont .menu_static span.image_icon_cont .dark_image {
	display: none;
}

.desktop_nav_cont .menu_static span.image_icon_cont .light_image {
	display: block;
}

.desktop_nav_cont.home_page_not_active .menu_static span.image_icon_cont .light_image {
	display: none;
}

.desktop_nav_cont.home_page_not_active .menu_static span.image_icon_cont .dark_image {
	display: block;
}

.desktop_nav_cont .for_logo .logo_black {
	display: none;
}

.desktop_nav_cont.home_page_not_active .for_logo .logo_white {
	display: none;
}

.desktop_nav_cont.home_page_not_active .for_logo .logo_black {
	display: block;
}

.desktop_nav_cont .cat_menu {
	background-color: transparent;
}

.desktop_nav_cont.scrolled .cat_menu {
	background-color: #28282d;
}

.desktop_nav_cont .col_center img {
	position: static;
	left: calc(50% - 15px);
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-o-transform: translateY(0px);
	transform: translateY(0px);
	opacity: 1;
	-webkit-transition: all .5s;
	transition: all .5s;
}

.desktop_nav_cont.scrolled .static_logo_container {
	font-size: 14px;
}

.desktop_nav_cont.scrolled .col_center img {
	position: absolute;
	-webkit-transform: translateY(-100px);
	-moz-transform: translateY(-100px);
	-o-transform: translateY(-100px);
	transform: translateY(-100px);
	opacity: 0;
	-webkit-transition: all .5s;
	transition: all .5s;
}

.static_logo_container,
.cat_menu {
	overflow: hidden;
	justify-content: space-between;
}

.static_logo_container {
	overflow: unset;
}

.desktop_nav_cont .insignia_desktop {
	position: absolute;
	// transform: translateY(-100px);
	opacity: 0;
	transition: all .5s;
}

.desktop_nav_cont.scrolled .insignia_desktop {
	position: static;
	// transform: translateY(0px);
	opacity: 1;
	transition: all .5s;
}

.desktop_nav_cont.scrolled .top_notification {
	display: none;
}

.more_option_button {
	width: auto;
	display: flex;
	align-items: center;
	align-self: center;
	background: #b7a98f;
	padding: 3px 7px;
	border-radius: 3px;
	color: #302e2e;
	font-weight: 500;
	cursor: pointer;
}

.a-main-margin {
	margin-top: 70px;

	@media screen and (max-width: 769px) {
		margin-top: 45px;
	}
}

.carousel_container {
	margin-top: -70px;
}

// navigation desktop end common

@media only screen and (max-width: 770px) and (min-width: 300px) {
	.story_action_wrapper .social_icon_cont {
		right: unset;
		top: -108px;
		left: -20vw;
	}
}

.multiple_slide_carousel {

	.owl-dots div.owl-dot.active span,
	.owl-dots div.owl-dot:hover span {
		background-color: yellow;
		border-radius: 50%;
		height: 16px;
		width: 16px;
	}

	.owl-dots div.owl-dot {
		border: 1px solid white;
		border-left: 0;
		border-right: 0;
		background: transparent;
		border-radius: 0;
		height: 10px;
		width: 51px;
		position: relative;
		overflow: hidden;
	}

	.owl-theme .owl-dots .owl-dot span {
		width: 100%;
		height: 100%;
		margin: 0;
		background: transparent;
		display: block;
		-webkit-backface-visibility: visible;
		transition: opacity .2s ease;
		border-radius: 0;
	}

	.owl-dots .owl-dot:hover span {
		background: white !important;
	}

	.owl-dots div.owl-dot:nth-child(1) {
		border-radius: 10px 0 0 10px;
		overflow: hidden;
	}

	.owl-dots div.owl-dot:nth-last-child(1) {
		border-radius: 0 10px 10px 0;
		overflow: hidden;
	}

	.owl-dots div.owl-dot.active span,
	.owl-dots div.owl-dot:hover span {
		border-radius: 0;
	}


	.owl-dot span {
		// width: 0%!important;
		transition: all .5s;
	}

	.owl-theme .owl-dots .owl-dot span {
		width: unset;
	}

	.owl-theme .owl-dots .owl-dot span {
		background: white;
	}

	.owl-dot span {
		background: blue;
		width: 0;
		transition: width .7s !important;
	}

	.owl-dot.active span {
		background: white !important;
		width: 100% !important;
		transition: width .7s !important;
	}

	.owl-dot span {
		transition: unset !important;
	}

	.owl-dot.active~.owl-dot span {
		background: green !important;
		width: 0% !important;
	}

	.owl-dots div.owl-dot.active span,
	.owl-dots div.owl-dot:hover span {
		width: unset;
		background-color: unset;
	}

	.owl-theme .owl-nav .owl-prev {
		width: 15px;
		height: 100px;
		position: absolute;
		top: 40%;
		margin-left: -30px;
		display: block !important;
		border: 0px solid black;
		background: transparent;
		color: black;
	}

	.owl-theme .owl-nav .owl-next {
		width: auto;
		height: 100px;
		position: absolute;
		top: 40%;
		right: -44px;
		display: block !important;
		border: 0px solid black;
		background: transparent;
		color: black;
	}


}


@media only screen and (max-width: 999px) and (min-width: 300px) {
	.multiple_slide_carousel .owl-dots div.owl-dot {
		width: 17px;
	}
}

// .mat-mdc-checkbox .mdc-checkbox{
// 	padding: 1px!important;
// }
// .mdc-checkbox__ripple{
// 	display: none;
// }
// .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
//     top: 0!important;
//     left: 0!important;
// }
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
	border-color: #948467 !important;
	background-color: #948467 !important;
}

.dd_tri .mat-mdc-checkbox label {
	cursor: pointer;
	text-transform: uppercase;
	color: #948467;
	//color: #6c5b48;
}

.drop_down_children_container .mat-mdc-checkbox label {
	cursor: pointer;
	text-transform: uppercase;
	color: black;
	font-size: 14px;
}

.dd_tri {
	margin-bottom: -10px;
}

.product_filter_cont .filter_individual_cont:nth-last-child(1) {
	border: none;
}

.cur_pointer {
	cursor: pointer;
}

.inner_html a {
	color: #948467;
	font-weight: 500;
	border-bottom: .5px dashed #948467;
}

.section_two .text_wrapper .inner_html {

	h1,
	h2,
	h3,
	h4,
	h5 {
		color: white;
	}
}

// testimonial slider
.testimonial_slider_cont .owl-carousel .owl-item img {
	//sdscsd
	width: unset;
}

.testimonial_slider_cont.global_testimonial .owl-carousel .owl-item .user_image img {
	//sdscsd
	width: 100px;
	border-radius: 100px;
	border: 2px solid white;
	display: block;
	margin: 0 auto;
}

.owl-carousel .owl-item .purchases_item_cont img {
	//sdscsd
	width: 58px;
	border-radius: 100px;
}

.flex {
	display: flex
}

.multiple_slide_carousel .owl-theme .owl-dots .owl-dot span {
	width: unset;
	background: #8e7860 !important;
}

.owl-dots div.owl-dot {
	border: 1px solid #8e7860;
	border-right: 0;
	border-left: 0;
}

.more_option_button.not_button_like {
	width: 63px;
	height: 63px;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	font-weight: 400;
	font-style: normal;
	background: white;
	margin-left: 5px;
}

.more_option_button.not_button_like.image_button {
	position: relative;
	padding: 0;
	margin-right: 10px;
}

.more_option_button.not_button_like.image_button img {
	width: 100%;
	position: relative;
}

.more_option_button.not_button_like.image_button span {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ngxImageZoomContainer {
	height: unset !important;
	width: unset !important;
}

@media only screen and (max-width: 999px) and (min-width: 300px) {
	.testimonial_slider_cont.global_testimonial .owl-carousel .owl-item .user_image img {
		margin-right: 10px;
		border: 2px solid #ffffff;
	}

	.testimonial_slider_cont.global_testimonial .owl-theme .owl-nav .owl-prev,
	.testimonial_slider_cont.global_testimonial .owl-theme .owl-nav .owl-next {
		background-color: transparent;
	}
}

@media only screen and (max-width: 5770px) and (min-width: 999px) {
	.testimonial_slider_cont.global_testimonial .owl-carousel.owl-theme {
		position: relative;
	}

	.testimonial_slider_cont.global_testimonial .owl-theme .owl-nav {
		position: absolute;
		top: 50%;
		width: 100%;
	}

	.testimonial_slider_cont.global_testimonial .owl-theme .owl-nav .owl-prev {
		position: absolute;
		left: -29px;
		background: transparent;
	}

	.testimonial_slider_cont.global_testimonial .owl-theme .owl-nav .owl-next {
		position: absolute;
		right: -37px;
		background: transparent;
	}
}

.for_home_slider .owl-carousel.owl-drag .owl-item {
	padding: 0px 20px !important;
}

.dark_arousol_outer .single_carousol .owl-dots div.owl-dot,
.light_arousol_outer .single_carousol .owl-dots div.owl-dot,
.home_banner_slider.single_carousol .owl-dots div.owl-dot {
	border: none !important;
}

.owl-theme .owl-nav.disabled+.owl-dots {
	margin-top: 10px;
	position: absolute;
	z-index: 2;
	bottom: 0;
	width: 100%;
	text-align: center;
}

.featured_slider .owl-theme .owl-nav.disabled+.owl-dots {
	position: relative;
	border: none !important;
}

.featured_slider .owl-dots div.owl-dot {
	border: none !important;
}

.animate_icon_button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 5px 24px 5px 20px;
	transition: all .5s;
}

.animate_icon_button:hover {
	padding: 5px 4px 5px 44px;
}

.animate_icon_button .animate {
	width: 20px;
	height: .5px;
	position: relative;
	border-bottom: 0.5px solid #9c8a6c;
}

.animate_icon_button .animate b {
	position: absolute;
	left: 0;
	top: -3.5px;
	width: 7px;
	height: 7px;
	background: #efeee9;
	transform: rotate(45deg);
	border: .5px solid #9c8a6c;
	opacity: 1;
	transition: all .5s;
}

.animate_icon_button .animate span {
	position: absolute;
	left: 3px;
	top: -5px;
	/* background: #000000; */
	width: 0;
	height: 0;
	border-top: 5.4px solid transparent;
	border-bottom: 5.4px solid transparent;
	border-left: 5.4px solid black;
	opacity: 0;
	transition: all .5s;
}

.animate_icon_button:hover .animate {
	transition: all .7s;
	border: .5px solid #000;
}

.animate_icon_button:hover .animate b {
	left: 11px;
	transition: all .1s;
	opacity: 0;
}

.animate_icon_button:hover .animate span {
	left: 16px;
	transition: all .5s;
	opacity: 1;
}


.animate_icon_button .text_animate_button {
	text-transform: uppercase;
	font-size: 13px;
}

.inner_html strong {
	color: unset;
}

// .prose{
// 	color:unset
// }
.filter_head {
	border-bottom: 1px solid black;
	border-radius: 0;
	color: black;
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-transform: uppercase;
	font-weight: 500;
	font-size: .9rem;
}

.ap-filter {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}

.filter_option_inner .filter_option_inner {
	margin-bottom: 0;
	margin-top: 0 !important;
}

.filter_option_inner.active {
	max-height: 1000px;
	overflow: hidden;
	margin-top: 10px;
	// margin-bottom: 10px;
	transition: all 0.5s;
}

.filter_option_inner {
	width: 100%;
	padding-left: 20px;
	max-height: 0px;
	overflow: hidden;
	transition: all 0.5s;
}

.filter_option:nth-last-child(1) .filter_head {
	border-bottom: 1px solid transparent !important;
}

.ap-filter-cc.ap-filter-tree-items {
	margin-bottom: -10px;
}

.items-center {
	.ngx-slider .ngx-slider-bubble {
		display: none;
	}
}

.items-center .ngx-slider {
	margin: 18px 0 15px;
}

.items-center .ngx-slider .ngx-slider-pointer:after {
	display: none !important;
	// content: '';
	// width: 14px;
	// height: 16px;
	// position: absolute;
	// top: 5px;
	// left: 6px;
	// border-radius: 4px;
	// background: #fff;
}

.items-center .ngx-slider .ngx-slider-pointer {
	cursor: pointer;
	width: 25px;
	height: 25px;
	top: -12px;
	z-index: 3;
	border-radius: 7px;
	background: #8e7860;
}

.items-center .ngx-slider .ngx-slider-selection {
	background: #8e7860;
}

.slider_cont {
	flex-wrap: wrap;
}

.slider_cont_inner {
	width: 100%;
}

.min_max_count {
	// display: flex;
	// width: 100%;
	// align-items: center;
	// justify-content: space-between;
}

.for_product_slider .owl-dots,
.for_product_slider .owl-nav {
	display: none;
}

.ngxImageZoomFullContainer.ngxImageZoomLensEnabled {
	border: 2px solid #b7a98f !important;
}

.choose_outer_fabric .more_info_outer_cont_wrapper {
	right: 103px;
	left: unset !important;
	bottom: -16vh !important;
	height: calc(82vh - 173px) !important;
}

.section_inner_container p.inner_html.prose ul li {
	position: relative;
	list-style: none;
	text-align: justify;
	@media screen and (min-width: 769px) {
		margin-left: 25px;
	}
}

.section_inner_container p.inner_html.prose ul li::before {
	position: absolute;
	content: "";
	width: 10px;
	height: 10px;
	background: #9c8a6c;
	left: -16px;
	top: 9px;
	border-radius: 100px;
}

.section_inner_container.section_two p.inner_html.prose ul li {
	color: white;
}

.even .iamge_cont span,
.odd .iamge_cont span {
	padding: 7px 25px;
	background: red;
	color: white;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 0px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
}

.overlay_pop_up_pd {
	position: fixed;
	background-color: red;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2;
	background: rgba(0, 0, 0, 0.57);
	border-radius: 0px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0);
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
}

.overlay_pop_up_pd.for_fabric {
	background: rgb(0 0 0 / 14%);
	backdrop-filter: blur(4px);
}

.pre_order_container {
	.choose_fabric_cont_inner {
		background: transparent !important;
	}

	.choose_finish_cont_inner {
		background: transparent !important;
	}
}

.pre_order_container .choose_outer_fabric.popup_active .morefabric_inner,
.pre_order_container .choose_outer_fabric.active_pop .morefabric_inner{
	// top:136px;
	// left: 0%;
	top: 45px;
    left: 50%;
	right: unset;
}

.pre_order_container .choose_outer_fabric.active_pop .morefabric_inner{
	top:336px;
	left: 0%;
	right: unset;
}

@media only screen and (max-width: 999px) and (min-width: 300px) {

	.even .iamge_cont span,
	.odd .iamge_cont span {
		padding: 5px 17px;
		font-size: 11px;
		margin: 0 auto;
	}
}

@keyframes slideIn {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(0);

	}
}


// nav styling
.cat_menu {
	width: 100%;
	// background: #28282d;
	padding: 0% 4%;
	overflow: hidden;
	// position: relative;
}

.insignia_desktop {
	width: auto;
	height: 20px;
	align-self: center;
}


.menu_ind {
	@apply relative;
	background: transparent;
	padding: 5px 10px;
	text-transform: uppercase;
	color: white;
	cursor: pointer;
	font-size: 14px;
}

.menu_ind:before {
	position: absolute;
	bottom: 5px;
	width: 0%;
	left: 10%;
	content: '';
	height: 2px;
	border-radius: 10px;
	background-color: white;
	transition: all .5s;
}

.menu_ind:hover:before {
	width: 80%;
	transition: all .5s;
}

.secondary_menu_container {
	width: 100%;
	left: 0%;
	// min-height: 100vh;
	min-height: auto;
	position: absolute;
	background-color: #efeee9;
	padding: 29px;
	background-position: center;
	background-repeat: no-repeat;
}


.secondary_menu_container_inner {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.secondary_menu_inner button {
	display: block;
}

.secondary_menu_inner {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}


.tertiary_ind span.tertiary_heading {
	font-size: 13px;
	color: #b7a88e;
	font-weight: 600;
	cursor: pointer;
}

.tertiary_ind button.tertiary_menu {
	padding: 2px 7px;
	border: 1px solid transparent;
	font-size: 13px;
	cursor: pointer;
	// opacity: 0.5;
	opacity: 1;
	text-transform: uppercase;
	color: #28282d;
	text-align: left;
}

.tertiary_ind button.tertiary_menu:hover {
	border: 1px solid black;
	opacity: 1;
	transition: all 0.5s;
}

.tertiary_ind button.tertiary_menu.hover_active {
	border: 1px solid black;
	opacity: 1;
	transition: all 0.5s;
}

.tertiary_heading {
	@apply uppercase
}

// .menu_ind_wrapper{
// 	width: 50%;
// 	padding-top: 30px;
// }
// .tertiary_menu_inner {
// 	width: 100%;
// 	margin-bottom: 20px;
// 	text-transform: uppercase;
// }


// craft manu


.image_container_for_nav img {
	align-self: start;
	margin-left: 7%;
}

.craft_wrapper::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.craft_wrapper::-webkit-scrollbar-thumb {
	background: #8d7961;
	border-radius: 10px;
}

.craft_wrapper::-webkit-scrollbar-thumb:hover {
	background: #655643;
}

.nav_sec_with_image_comp {
	width: 50%;
}


.craft_wrapper::-webkit-scrollbar {
	width: 5px;
}

/* Track */
.craft_wrapper::-webkit-scrollbar-track {
	// background: #e5c59c;
	border: .5px solid #97764c;
	border-radius: 100px;
	width: 5px;
}

/* Handle */
.craft_wrapper::-webkit-scrollbar-thumb {
	background: #97764c;
	cursor: pointer;
	width: 5px;
}

/* Handle on hover */
.craft_wrapper::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.product_listing_container_in_sd .product_listing_cont_inner {
	gap: 13px !important;
}

.desktop_nav_cont.scrolled .static_logo_container * {
	color: black !important;
}

.desktop_nav_cont.home_page_not_active .static_logo_container * {
	color: black !important;
}

.desktop_nav_cont.home_page_not_active .menu_ind_outer {
	padding-top: 4px;
}

.desktop_nav_cont.home_page_not_active.scrolled .menu_ind_outer {
	padding-top: 0px;
}

.page-container.page-container {
	max-width: 1400px;
	padding: 0 50px;
}

.secondary_menu_container .page-container.page-container {
	padding: 0 0;
	height: 57vh;
	overflow: hidden;
}

.for_home_slider .owl-carousel .owl-stage-outer {
	padding: 0px 30px;
}

.menu_ind.for_search {
	font-size: 16px;
}

.menu_ind.for_search {
	font-size: 16px;
	margin-top: -3px;
}

.scrolled .menu_ind.for_search {
	margin-top: 0px;
}
.hide_span {
	display: none!important;
}
.hide_forrex .forex_container, .hide_forrex .country_name_cont {
	display: none;
}
.clear_cart_button {
	display: none;
}
.popupOpen_body_wrapper .light_arousol_outer, .popupOpen_body_wrapper .dark_arousol_outer {
	position: relative;
	z-index: -1;
}
.body_wrapper.popupOpen_body_wrapper .addressC_list_cont{
	position: fixed;
    width: 100%;
    height: 100%;
	left: 0;
	top: 0;
	transition: all .5s;
	overflow-x: hidden;
}
@media only screen and (max-width: 999px) and (min-width: 300px) {

	.popupOpen_body_wrapper .top_notification {
		display: none;
	}

	.page-container.page-container {
		padding: 0 0px;
		max-width: 100dvh;
		height: auto;
		overflow: auto;
	}

	.secondary_menu_container .page-container.page-container {
		overflow: auto;
	}
	.desktop_nav_cont {
		position: relative;
	}

	.desktop_nav_cont .col_center span {
		display: inline-block !important;
		font-size: 17px;
		margin-left: 10px;
	}

	.desktop_nav_cont .menu_static div {
		transition: all .5s;
		display: block;
		font-size: 14px;
		opacity: 1;
	}

	.desktop_nav_cont.scrolled .menu_static div {
		transition: all .5s;
		display: block;
		font-size: 0;
		opacity: 0;
	}

	.desktop_nav_cont.scrolled .col_center img {
		position: static;
		transform: translateY(0px);
		opacity: 1;
	}

	.desktop_nav_cont .cat_menu.for_mobile {
		background: #efeee9;
		position: fixed;
		width: 89%;
		left: -100%;
		padding: 10px 20px;
		height: calc(100vh - 44px);
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		display: flex;
		align-content: flex-start;
		overflow: auto;
		transition: all .5s;
	}

	.desktop_nav_cont .cat_menu.for_mobile.active {
		left: 0;
		transition: all .5s;
	}

	.menu_ind {
		color: black;
		width: 100%;
		display: flex;
		font-size: 17px;
		justify-content: space-between;
		border-bottom: 1px solid #b7a98f;
		font-weight: 600;
		padding: 10px 0;
		transition: unset;
		background: #efeee9;

	}

	.menu_ind.active {
		border-bottom: 0 solid #b7a98f;

	}

	.secondary_menu_container {
		position: relative;
		background-color: #efeee9;
		padding: 0 0 12px 12px;
	}

	.menu_ind:hover:before {
		display: none;
	}

	.tertiary_ind {
		padding-left: 28px;
		padding-top: 8px;
		padding-bottom: 5px;
		position: relative;
	}

	.tertiary_ind span.tertiary_heading {
		font-size: 13px;
		color: #b7a88e;
		cursor: pointer !important;
		font-weight: 600;
		margin-left: -10px;
		margin-bottom: 8px;
		display: block;
	}

	.tertiary_ind button.tertiary_menu.hover_active {
		border: 0 solid black;
	}

	.secondary_menu_container {
		display: none;
	}

	.secondary_menu_container.active {
		display: block;
	}

	.menu_ind .material-symbols-outlined {
		transform: rotate(0) translateY(0);
		transition: all .5s;
	}

	.menu_ind.active .material-symbols-outlined {
		transform: rotate(179deg) translateY(5px);
		transition: all .5s;
	}

	.menu_ind_secondary .material-symbols-outlined {
		transform: rotate(0) translateY(0);
		transition: all .5s;
		background: transparent;
	}

	.tertiary_ind::before {
		content: "";
		position: absolute;
		left: 6px;
		width: 1px;
		height: 100%;
		top: 2%;
		background: #c4a88e;
	}

	.menu_ind_secondary.active .material-symbols-outlined {
		transform: rotate(179deg) translateY(5px);
		transition: all .5s;
	}

	.menu_ind_wrapper {
		padding-left: 5px;
	}

	.menu_ind_secondary {
		width: 100%;
		//margin-left: 0;
		margin: 0;
	}

	.menu_ind_secondary>span {
		background: #efeee9;
		padding: 6px 0px;
		position: relative;
	}

	.desktop_nav_cont.scrolled .menu_ind {
		padding: 10px 0;
		font-size: 17px;
		transition: all ease-in-out 0.5s;
	}

	.menu_static span {
		display: block;
		//font-size: 18px;
	}

	.desktop_nav_cont.scrolled button.menu_static.hamburger span {
		font-size: 18px !important;
	}

	.desktop_nav_cont.scrolled button.menu_static.hamburger {
		transform: translateY(12px);
	}

	.desktop_nav_cont.scrolled .menu_static span.image_icon_cont .dark_image {
		display: none;
	}

	.desktop_nav_cont.scrolled .menu_static span.image_icon_cont .light_image {
		display: block;
	}

	.desktop_nav_cont.home_page_not_active .menu_static span.image_icon_cont .light_image {
		display: block;
	}

	.desktop_nav_cont.home_page_not_active .menu_static span.image_icon_cont .dark_image {
		display: none;
	}

	.for_home_slider .owl-carousel.owl-drag .owl-item {
		padding: 0px 0px !important;
	}

	.for_home_slider .owl-carousel .owl-stage-outer {
		padding: 0px 0px;
	}

}

@media only screen and (max-width: 1200px) and (min-width: 800px) {
	.menu_ind {
		color: black;
		width: 100%;
		display: flex;
		font-size: 17px;
		justify-content: space-between;
		border-bottom: 1px solid #b7a98f;
		font-weight: 600;
		padding: 10px 0;
		transition: unset;
		background: #efeee9;
	}

	.secondary_menu_container .page-container.page-container{
		overflow: auto;
	}
	.secondary_menu_container {
		display: none;
	}
	.secondary_menu_container.active {
		display: block;
	}

	.desktop_nav_cont .cat_menu.for_mobile {
		background: #efeee9;
		position: fixed;
		width: 89%;
		left: -100%;
		padding: 10px 20px;
		height: calc(100vh - 44px);
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		display: flex;
		align-content: flex-start;
		overflow: auto;
		transition: all 0.5s;
	}
	.desktop_nav_cont .cat_menu.for_mobile.active {
		left: 0;
		transition: all 0.5s;
	}
}

.fb_animate_icon_button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 5px 24px 5px 0;
	transition: all .5s;
	width: max-content;

	.fb_animate {
		width: 20px;
		height: .5px;
		position: relative;
		border-bottom: 0.5px solid #9c8a6c;

		b {
			position: absolute;
			left: 0;
			top: -3.5px;
			width: 7px;
			height: 7px;
			background: #efeee9;
			transform: rotate(45deg);
			border: .5px solid #9c8a6c;
			opacity: 1;
			transition: all .5s;
		}

		span {
			position: absolute;
			left: 3px;
			top: -5px;
			/* background: #000000; */
			width: 0;
			height: 0;
			border-top: 5.4px solid transparent;
			border-bottom: 5.4px solid transparent;
			border-left: 5.4px solid black;
			opacity: 0;
			transition: all .5s;
		}
	}

	&:hover {
		padding: 5px 4px 5px 5px;

		.fb_animate {
			transition: all .7s;
			border: .5px solid #000;

			b {
				left: 11px;
				transition: all .1s;
				opacity: 0;
			}

			span {
				left: 16px;
				transition: all .5s;
				opacity: 1;
			}
		}
	}


}

.owl-theme .owl-nav .owl-next, .owl-theme .owl-nav .owl-prev{
	background: #9c8a6c !important;
	border: 1px solid transparent;
	transition: all .5s ease-in-out;

	&:hover {
		border: 1px solid #9c8a6c;
		background: transparent !important;
		color: #9c8a6c;
	}
}

@keyframes slide-in-left {
	0% {
	  transform: translateX(-100%);
	  opacity: 0.4;
	}
	100% {
	  transform: translateX(0);
	  opacity: 1;
	}
  }

  @keyframes slide-in-right {
	0% {
	  transform: translateX(100%);
	  opacity: 0.4;
	}
	100% {
	  transform: translateX(0);
	  opacity: 1;
	}
  }


  .fb-f-product {
	  position: relative;
	  .owl-theme .owl-nav {
		  position: absolute;
		  top: 16vh;
		  width: 100%;
		  display: flex;
		  justify-content: space-between;

		  .owl-next, .owl-prev {
			  background: #9c8a6c !important;
			  border: 1px solid transparent;
			  transition: all 0.5s ease-in-out;
			  height: 40px;
			  font-weight: bold;
			  display: flex;
			  justify-content: center;
			  align-items: center;

			  &:hover {
				  color: white;
			  }
		  }
	  }
  }

  .fb-blog-content {
	  p {
		  a {
			  color: #7d5b1f !important;
			  font-weight: 500 !important;
			  border-bottom: .5px dashed #948467 !important;
		  }
	  }

	  ol {
		  li {
			  @apply list-decimal list-inside px-2 py-1;
		  }
	  }

	  ul {
		  li {
			  @apply list-disc list-inside px-2 py-1;
		  }
	  }
  }
